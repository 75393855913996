import React from 'react';
import MainSection from "../sections/Accueil/MainSection";
import SecondSection from "../sections/Accueil/SecondSection";
import ThirdSection from "../sections/Accueil/ThirdSection";
import '../assets/css/style.css'
import '../assets/css/accueil.css';


function Accueil() {
    return (
        <>
            <MainSection />
            <SecondSection />
            <ThirdSection />
        </>

    );
}

export default Accueil;

