import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider, useTheme} from "./contexts/Themecontext";
import PublicRoutes from './routes/PublicRoutes';
import Header from "./components/Header";
import Footer from './components/Footer';

function AppWrapper() {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    );
}

function App() {
    const { theme } = useTheme();
    return (
        <Router>
            <div className={`app ${theme}`}>
                <Header/>
                <PublicRoutes />
                <Footer />
            </div>
        </Router>
    );
}
export default AppWrapper;