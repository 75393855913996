import React, { useState, useEffect } from 'react';
import { Switch } from "@mui/material";
import i18n from 'i18next'; // Assurez-vous d'avoir importé i18n

function LanguageSwitch() {
    const [isEnglish, setIsEnglish] = useState(i18n.language === 'en');

    useEffect(() => {
        // Mettre à jour la langue lorsque l'état change
        const language = isEnglish ? 'en' : 'fr';
        i18n.changeLanguage(language);
    }, [isEnglish]);

    // Gérer le changement de langue
    const handleLanguageChange = () => {
        setIsEnglish(!isEnglish);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8, color: isEnglish ? 'grey' : 'black' }}>FR</span>
            <Switch
                checked={isEnglish}
                onChange={handleLanguageChange}
            />
            <span style={{ marginLeft: 8, color: isEnglish ? 'black' : 'grey' }}>EN</span>
        </div>
    );
}

export default LanguageSwitch;
