import React, { useState ,useEffect } from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import { useTheme } from '../../contexts/Themecontext';
function MainSection() {
    const { t } = useTranslation();

    const { theme } = useTheme();

        const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const translateYLeft = -scrollPosition  / 2;
  const translateYRight = scrollPosition  / 2;
    return (
        <div style={{ textAlign: 'center', height: "500px" }}>
            <div className='svg2-style'>
                {theme === 'dark' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" viewBox="0 0 190 190" fill="none">
                        <g filter="url(#filter0_f_408_1329)">
                            <circle cx="95" cy="95" r="65" fill="url(#paint0_radial_408_1329)"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_408_1329" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_408_1329"/>
                            </filter>
                            <radialGradient id="paint0_radial_408_1329" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.7917 20.7916) rotate(87.7365) scale(137.149 1615.67)">
                                <stop stopColor="#494955"/>
                                <stop offset="1" stopColor="#141414"/>
                            </radialGradient>
                        </defs>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg"  width="100" viewBox="0 0 190 190" fill="none">
                        <g filter="url(#filter0_f_515_366)">
                            <circle cx="95" cy="95" r="65" fill="url(#paint0_radial_515_366)"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_515_366" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_515_366"/>
                            </filter>
                            <radialGradient id="paint0_radial_515_366" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.7917 20.7916) rotate(87.7365) scale(137.149 1615.67)">
                                <stop stopColor="#D2D4DA"/>
                                <stop offset="1" stopColor="#B7BBC5"/>
                            </radialGradient>
                        </defs>
                    </svg>
                )}
            </div>
            <div className='flex-centered'>
                <div style={{ marginTop: "15%"}} >
                <p className='principal-title'>{t('revolution')} <br/>Linux</p>
                <p style={{color: '#9E9E9E'}}>{t('explore')}</p>
                <br/>
                <Link to="/download" className='button-download'>{t('download')}</Link>
                </div>
            </div>
            <div className="d-none d-lg-block svg1-style">
                {theme === 'dark' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="190" viewBox="0 0 190 190" fill="none" style={{ transform: `translateY(${translateYLeft}px)` }}>
                        <g filter="url(#filter0_f_408_1329)">
                            <circle cx="95" cy="95" r="65" fill="url(#paint0_radial_408_1329)"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_408_1329" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_408_1329"/>
                            </filter>
                            <radialGradient id="paint0_radial_408_1329" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.7917 20.7916) rotate(87.7365) scale(137.149 1615.67)">
                                <stop stopColor="#494955"/>
                                <stop offset="1" stopColor="#141414"/>
                            </radialGradient>
                        </defs>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="190"  viewBox="0 0 190 190" fill="none" style={{ transform: `translateY(${translateYLeft}px)` }}>
                        <g filter="url(#filter0_f_515_366)">
                            <circle cx="95" cy="95" r="65" fill="url(#paint0_radial_515_366)"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_515_366" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_515_366"/>
                            </filter>
                            <radialGradient id="paint0_radial_515_366" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.7917 20.7916) rotate(87.7365) scale(137.149 1615.67)">
                                <stop stopColor="#D2D4DA"/>
                                <stop offset="1" stopColor="#B7BBC5"/>
                            </radialGradient>
                        </defs>
                    </svg>
                )}
            </div>
            <div className="d-none d-lg-block svg3-style">
                {theme === 'dark' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="160" viewBox="0 0 190 190" fill="none" style={{ transform: `translateY(${translateYRight}px)` }}>
                        <g filter="url(#filter0_f_408_1329)">
                            <circle cx="95" cy="95" r="65" fill="url(#paint0_radial_408_1329)"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_408_1329" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_408_1329"/>
                            </filter>
                            <radialGradient id="paint0_radial_408_1329" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.7917 20.7916) rotate(87.7365) scale(137.149 1615.67)">
                                <stop stopColor="#494955"/>
                                <stop offset="1" stopColor="#141414"/>
                            </radialGradient>
                        </defs>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="160"  viewBox="0 0 190 190" fill="none" style={{ transform: `translateY(${translateYRight}px)` }}>
                        <g filter="url(#filter0_f_515_366)">
                            <circle cx="95" cy="95" r="65" fill="url(#paint0_radial_515_366)"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_515_366" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_515_366"/>
                            </filter>
                            <radialGradient id="paint0_radial_515_366" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.7917 20.7916) rotate(87.7365) scale(137.149 1615.67)">
                                <stop stopColor="#D2D4DA"/>
                                <stop offset="1" stopColor="#B7BBC5"/>
                            </radialGradient>
                        </defs>
                    </svg>
                )}
            </div>
            
        </div>
    );
}
export default MainSection;