import React from 'react';
import { useTheme} from "../contexts/Themecontext";
import MainSection from "../sections/Download/MainSection";
import SecondSection from "../sections/Download/SecondSection";
import '../assets/css/download.css'

function Download() {
    const { theme } = useTheme();
    return (
        <div className={`app ${theme}`}>
           <MainSection/>
           <SecondSection/>
        </div>
    );
}
export default Download;
