import React from 'react';
import logo from "../assets/images/logo-rekali.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "../contexts/Themecontext";
import { Link } from "react-router-dom";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import { LinkedIn, Twitter } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";

function Footer() {
    const { theme } = useTheme();
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width:767px)');

    const p = {
        fontWeight: "600",
        fontSize: "16px",
        color: theme === 'dark' ? 'white' : 'black', // Adjust text color based on theme
        textDecoration: 'none', // Adjust text decoration as needed
    };

    const logoStyle = {
        marginLeft: isSmallScreen ? '-1.5rem' : '9rem',
        width: isSmallScreen ? '60%' : '11%',
    };
    const iconStyle = {
        marginRight: "20px",
        padding: '9px',
        background: "#18181C",
        color: "white",
        borderRadius: "11px",
        flexDirection: "column",
    }
    const rightTextStyleFooter = {
        fontWeight: "600",
        fontSize: "16px",
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '3rem' }} className={theme + "secondary"}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} style={logoStyle} alt={"Logo ReKali"} />
                    </div>
                    <section style={{ marginRight: "50px", marginTop: "20px" }}>
                        <p style={{ fontWeight: "600", fontSize: "16px" }}>Sections</p>
                        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                            <Link to="/" style={p}>{t('home')}</Link>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            <Link to="/download" style={p}>{t('download')}</Link>
                        </div>
                        <div>
                            <Link to="/team" style={p}>{t('team')}</Link>
                        </div>
                    </section>
                </footer>
            </Grid>
            <Grid item>
                <footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '3rem' }}>
                    <section>
                        <Typography style={rightTextStyleFooter}>{t('right')}</Typography>
                    </section>
                    <section >
                        <Row>
                            <Col>
                                <IconButton style={iconStyle} aria-label="twitter">
                                    <Twitter />
                                </IconButton>
                            </Col>
                            <Col>
                                <IconButton style={iconStyle} aria-label="linkedin">
                                    <LinkedIn />
                                </IconButton>
                            </Col>
                        </Row>
                    </section>
                </footer>
            </Grid>
        </Grid>
    );
}

export default Footer;

