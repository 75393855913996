import React from 'react';
import { useTranslation } from "react-i18next";
import FeatherIcon from 'feather-icons-react';
import image from "../../assets/images/image.png";
import { useTheme } from "../../contexts/Themecontext";
import { Row, Col, Container } from 'react-bootstrap';

function SecondSection() {
    const { t } = useTranslation();
    const { theme } = useTheme();

    return (
        <>
            <div className="div-style">
                <div className="flex-centered">
                    <div className={`${theme}interface card-style-laptop`}>
                        <div className={`${theme}interface card-style-image`}>
                            <img src={image} className="laptop-image" alt={"ReKali"} />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="flex-title">
                    <div className="title-section">
                        <h1 className="values">{t('values')}</h1>
                        <p className="p1">{t('p1')}<br />{t('p2')}</p>
                    </div>
                </div>
                <Container className='container-centered'>
                    <Row className="row-centered" lg={3} md={2} sm="auto" xs="auto">
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`}>
                                <div className="icon-container">
                                    <FeatherIcon icon="package" size="50" color="white" />
                                </div>
                                <p className="title">{t('CardTitle1')}</p>
                                <p className="description">{t('card1')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`}>
                                <div className="icon-container">
                                    <FeatherIcon icon="star" size="50" color="white" />
                                </div>
                                <p className="title">{t('CardTitle2')}</p>
                                <p className="description">{t('card2')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`}>
                                <div className="icon-container3">
                                    <FeatherIcon icon="smile" size="50" color="white" />
                                </div>
                                <p className="title">{t('CardTitle3')}</p>
                                <p className="description">{t('card3')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`}>
                                <div className="icon-container3">
                                    <FeatherIcon icon="book-open" size="50" color="white" />
                                </div>
                                <p className="title">{t('CardTitle4')}</p>
                                <p className="description">{t('card4')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`}>
                                <div className="icon-container">
                                    <FeatherIcon icon="package" size="50" color="white" />
                                </div>
                                <p className="title">{t('CardTitle5')}</p>
                                <p className="description">{t('card5')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`}>
                                <div className="icon-container">
                                    <FeatherIcon icon="settings" size="50" color="white" />
                                </div>
                                <p className="title">{t('CardTitle6')}</p>
                                <p className="description">{t('card6')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default SecondSection;