import React from 'react';
import { useTranslation } from "react-i18next";
import image from '../../assets/images/windows-logo.png';
import { useTheme } from "../../contexts/Themecontext";
import linuxImage from '../../assets/images/linux-logo.png';
import macImage from '../../assets/images/finder-macOS.png';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

function SecondSection() {

    const { t } = useTranslation();

    const styleButtonDownload = {
        background: 'linear-gradient(to left, #8054FF, #FF9898)',
        padding: '13px',
        borderRadius: '5px',
        color: 'white',
        borderStyle: 'solid',
        marginTop: '50px',
        border: 'none',
        textDecoration: 'none',
    };

    function downloadFile(osname, exename) {
        // Remplacer 'url_de_votre_api' par l'URL réelle de votre API
        fetch(`http://127.0.0.1:8000/getExecutable/${osname}`)
          .then(response => response.blob()) // Transforme la réponse en Blob
          .then(blob => {
            // Crée un URL pour le Blob
            const url = window.URL.createObjectURL(new Blob([blob]));
            // Crée un élément a pour le téléchargement
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exename); // Définit le nom du fichier à télécharger
            // Append l'élément a au body
            document.body.appendChild(link);
            // Déclenche le téléchargement
            link.click();
            // Nettoie et enlève l'élément a
            link.parentNode.removeChild(link);
          })
          .catch(error => console.error('Error:', error));
      }
      


    const { theme } = useTheme();
    return (
        <>
            <div className="div-style-cutted">
                <div className="flex-centered">
                    <Container className='container-centered' style={{ marginBottom: "20%" }}>
                        <Row className="row-centered" lg={3} md="auto" sm="auto" xs="auto" >
                            <Col sm className="col-centered">
                                <div className={`${theme}interface graphic-interface-download`} disabled>
                                    <div className="icon-download">
                                        <img src={image} style={{ width: '88px', justifyContent: "center", alignItems: "center" }} alt={"Windows logo"} />
                                    </div>
                                    <Link
                                        onClick={() => downloadFile("windows", "rekali.exe")}
                                        style={styleButtonDownload}
                                    >
                                        {t('download')}
                                    </Link>
                                </div>
                            </Col>
                            <Col sm className="col-centered">
                                <div className={`${theme}interface graphic-interface-download`} disabled>
                                    <div className="icon-download">
                                        <img src={linuxImage} style={{ width: '128px', justifyContent: "center", alignItems: "center" }} alt={"Windows logo"} />
                                    </div>
                                    <Link
                                        onClick={() => downloadFile("linux", "rekali.deb")}
                                        style={styleButtonDownload}
                                    >
                                        {t('download')}
                                    </Link>
                                </div>
                            </Col>
                            <Col sm className="col-centered">
                                <div className={`${theme}interface graphic-interface-download`} disabled>
                                    <div className="icon-download">
                                        <img src={macImage} style={{ width: '88px', justifyContent: "center", alignItems: "center" }} alt={"Windows logo"} />
                                    </div>

                                    <Link
                                        onClick={() => downloadFile("mac", "rekali.dmg")}
                                        style={styleButtonDownload}
                                    >
                                        {t('download')}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}
export default SecondSection;