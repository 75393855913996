import React from 'react';
import {useTranslation} from "react-i18next";

function MainSection() {

    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center' }}>
            <div>
                <p style={{fontWeight: '600', fontSize: '64px', marginTop: "20px", marginBottom: "2rem"}}>{t('team')}</p>
            </div>
            <p style={{color: '#9E9E9E', marginTop: '-2.2rem', marginBottom: '3rem'}}>{t('discoverTeam')}</p>
        </div>
    );
}
export default MainSection;