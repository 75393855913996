import React from 'react';
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";

function MainSection() {

    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'center' }}>
            <Grid container direction="column" alignItems="center">
                    <p style={{ fontWeight: '600', fontSize: '64px', marginTop: "30px" }}>
                        {t('dp1')}
                    </p>
                    <p style={{ fontWeight: '600', fontSize: '64px', marginTop: '-25px' }}>
                        {t('dp2')}
                    </p>
                    <p style={{ color: '#9E9E9E', marginTop: '1rem', marginBottom: '3rem' }}>
                        {t('dp3')}
                    </p>
            </Grid>
        </div>
    );
}
export default MainSection;