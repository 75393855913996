import React from 'react';
import { useTranslation } from "react-i18next";
import { useTheme } from "../../contexts/Themecontext";
import { Row, Col, Container } from 'react-bootstrap';
// import FeatherIcon from 'feather-icons-react';
import '../../assets/css/team.css'


function SecondSection() {

    const { t } = useTranslation();

    const { theme } = useTheme();
    return (
        <>
            <div className="div-style-centered">
                <Container className='container-centered'>
                    <Row className="row-centered" lg={3} md={2} sm="auto" xs="auto">
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://media.licdn.com/dms/image/C5603AQHI5HvDbrn83g/profile-displayphoto-shrink_200_200/0/1632990951635?e=2147483647&v=beta&t=1zgWKMeTrS_cGweLrN1V8H9R8Z391VNlDe3Jae0RmY0" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Eitan AMRAM</p>
                                <p className="description">{t('d1')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://media.licdn.com/dms/image/D4E03AQFkuwZXKMArxw/profile-displayphoto-shrink_800_800/0/1689857273070?e=1718841600&v=beta&t=JmJVD4BO-wTIdS2Y7--u1ZH_d2LHnKEyegYwNn12DAA" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Hugo ETCHEVARNE</p>
                                <p className="description">{t('d2')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container3">
                                    <img src="https://media.licdn.com/dms/image/D4E03AQENLFO-m4MN7Q/profile-displayphoto-shrink_800_800/0/1665477818960?e=1718841600&v=beta&t=aoGg8y-e7LR_pAXshPfAlJqRngqtTC7t_JxVvDwanIQ" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Thomas GILLET</p>
                                <p className="description">{t('d3')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container3">
                                    <img src="https://media.licdn.com/dms/image/C4D03AQFMnDgxq9wPYg/profile-displayphoto-shrink_800_800/0/1643665833375?e=1718841600&v=beta&t=96exkmv_B9GkgfyvNPnpDsFusYbyNUsfL5y3u0Md4CU" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Enzo BIJAOUI</p>
                                <p className="description">{t('d4')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://media.licdn.com/dms/image/C4D03AQFAvruuEa2N2g/profile-displayphoto-shrink_800_800/0/1622036433124?e=1718841600&v=beta&t=M_EepgfPFlcJzY--ThnuF9wpW-j9KcO1rX9lBBSBCUM" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Jeremy MADAR</p>
                                <p className="description">{t('d5')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://media.licdn.com/dms/image/C4E03AQFYPlNq661XDw/profile-displayphoto-shrink_800_800/0/1600781828694?e=1718841600&v=beta&t=WxLIbufYZWceDoafctwn43pom1HRHbh54rgLOS_ra1Q" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Matthias LELLOUCHE</p>
                                <p className="description">{t('d6')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://media.licdn.com/dms/image/C4E35AQEskcajBtdAaw/profile-framedphoto-shrink_800_800/0/1602088682104?e=1714125600&v=beta&t=yFs-3vvRQJjLGNDU3LKio4eePLtvs7FYpeiiR2gdSco" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Meir BELAICH</p>
                                <p className="description">{t('d7')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://thispersondoesnotexist.com/" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />

                                </div>
                                <p className="title">Nicolas MARY</p>
                                <p className="description">{t('d8')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://thispersondoesnotexist.com/" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Amine SARDAOUI</p>
                                <p className="description">{t('d9')}</p>
                            </div>
                        </Col>
                        <Col sm className="col-centered">
                            <div className={`${theme}interface graphic-interface`} style={{minHeight: '380px'}}>
                                <div className="icon-container">
                                    <img src="https://thispersondoesnotexist.com/" alt="icon" width={"80px"} style={{ borderRadius: "15px" }} className='image-teams' />
                                </div>
                                <p className="title">Zakaria LARAFI</p>
                                <p className="description">{t('d10')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default SecondSection;