import React from 'react';
import MainSectionTeam from "../sections/Team/MainSection";
import SecondSectionTeam from "../sections/Team/SecondSection";
import '../assets/css/team.css'


function Team() {
    return (
        <>
            <MainSectionTeam />
            <SecondSectionTeam/>
        </>

    );
}

export default Team;

