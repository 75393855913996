import React from 'react';
import { Route, Routes } from "react-router-dom";
import Accueil from '../pages/Acceuil';
import Download from "../pages/Download";
import Team from "../pages/Team";

function PublicRoutes() {
    return (
            <Routes>
                <Route path="/" element={<Accueil />} />
                <Route path="/download" element={<Download />} />
                <Route path="/team" element={<Team />} />
            </Routes>
    );
}
export default PublicRoutes;