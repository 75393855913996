import React, { useState } from 'react';
import LanguageSwitch from "./LanguageSwitch";
import ThemeSwitch from "./ThemeSwitch";
import logo from "../assets/images/logo.png";
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useTheme } from "../contexts/Themecontext";
import '../assets/css/header.css';


function Header() {

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };
    const {t} = useTranslation();
    const {theme} = useTheme();

    return (
        <>
            <div className="d-none d-lg-block">
                <header
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={logo} style={{marginRight: '2rem', width: '5%'}} alt={"Logo ReKali"}/>
                        <h1> ReKali </h1>
                    </div>
                    <div>
                        <div style={{display: 'flex'}}>
                            <Link to="/" style={{
                                marginRight: '1rem',
                                marginTop: '0.9rem',
                                textDecoration: 'none',
                                color: 'inherit'
                            }}>{t('home')}</Link>
                            <Link to="/download" className='button-download-header'>{t('download')}</Link>
                            <ThemeSwitch/>
                            <LanguageSwitch/>
                        </div>
                    </div>
                </header>
            </div>
            <div className="d-lg-none">
                <Navbar expand="lg" expanded={expanded}>
                    <Container>
                        <Navbar.Brand className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <img
                                    src={logo}
                                    style={{width: '25%'}}
                                    alt="Logo ReKali"
                                    className="ml-2"
                                />
                                <Link to="/" style={{textDecoration: 'none', color: 'inherit'}}>
                                    <h1 style={{margin: 0}} className={theme+"-mode"}>ReKali</h1>
                                </Link>
                            </div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
                        </Navbar.Brand>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Link to="/" style={{marginRight: '1rem', marginTop: '0.9rem', textDecoration: 'none', color: 'black'}}
                                      className="text-center mb-3">
                                    {t('home')}
                                </Link>
                                <Link to="/download" className='button-download-header'>{t('download')}</Link>
                                <div className="d-flex align-items-center mt-3">
                                    <div className="mr-3">
                                        <ThemeSwitch/>
                                    </div>
                                    <div>
                                        <LanguageSwitch/>
                                    </div>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
        ;
}

export default Header;

