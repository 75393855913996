import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Col, Row, Container } from "react-bootstrap";
import { useTheme } from "../../contexts/Themecontext";
import image from "../../assets/images/Group123.svg";
function ThirdSection() {
    const { theme } = useTheme();
    const styleButtonDownload = {
        background: 'linear-gradient(to left, #FF9898, #8054FF)',
        marginRight: '1rem',
        padding: '12px 30px',
        borderRadius: '5px',
        width: '145px',
        height: '44px',
        color: 'white',
        borderStyle: 'solid',
        borderWidth: '1px',
        border: 'none',
        textDecoration: 'none',
    };
    const isSmallScreen = useMediaQuery('(max-width:767px)');

    const tp7 = {
        fontWeight: "600",
        textAlign: isSmallScreen ? 'justify' : 'center',
        fontSize: isSmallScreen ? '11px' : '20px',
        padding: isSmallScreen ? '0' : '10px',
        color: "white",
        lineHeight: isSmallScreen ? '1.9' : '1.9',
        marginTop: isSmallScreen ? '20px' : '30px',
    }
    const tp8 = {
        backgroundColor: "black",
        color: "white",
        marginTop: isSmallScreen ? '20px' : '20px',
        padding: isSmallScreen ? '4px' : '12px 28px 12px 28px',
        borderRadius: '5px',
        textDecoration: 'none',
        fontSize: isSmallScreen ? '11px' : '20px',
    }

    const { t } = useTranslation();
    return (
        <>
            <Container className='flex-centered' style={{ padding: "50px 0px" }}>
                <Row className="row-centered" lg={2} md={2} sm="auto" xs="auto">
                    <Col sm className="col-centered-third-section">
                        <img src={image} alt="Images" className="image-style"/>
                    </Col>
                    <Col sm className="col-centered-third-section">
                        <div className='col-center-horizontally'>
                            <h1>{t('th1')} {t('th2')} {t('th3')}</h1><br />
                            <p style={{ color: "#9E9E9E" }}>{t('tp1')} {t('tp2')} {t('tp3')} {t('tp4')}</p>
                            <div style={{ marginTop: "3rem" }}>
                                <Link to="/download" style={styleButtonDownload}>{t('download')}</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div style={{  height: "550px" }} className={`${theme}interface`}>
                <p style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "48px",
                    marginBottom: "100px",
                    paddingTop: "130px"
                }}>{t('tp5')}</p>
            </div>
            <div className='flex-centered' style={{marginBottom: "30px"}}>
                <div style={{ width: "80%", marginTop: "-250px" }}>
                    <div className='div-section-team'>
                        <p style={{ fontWeight: "400", fontSize: "20px", marginTop: "-10px", color: "white", }}>{t('tp6')}</p>
                        <p style={tp7}>{t('tp7')}</p>
                        <Link to="/team" style={tp8}>{t('tp8')}</Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ThirdSection;
