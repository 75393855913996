// ThemeSwitch.js
import React from 'react';
import { useTheme } from '../contexts/Themecontext';
import { Switch } from "@mui/material";
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Icône de soleil
import Brightness2Icon from '@mui/icons-material/Brightness2'; // Icône de lune

function ThemeSwitch() {
    const { theme, toggleTheme } = useTheme();
    const isDarkMode = theme === 'dark';

    // Style conditionnel pour le Switch
    const switchStyles = {
        color: isDarkMode ? 'white' : 'black',
    };
    const colorIcon = {
        color: '#FFE700',
    }

    return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Switch
            checked={isDarkMode}
            onChange={toggleTheme}
            style={switchStyles}
            icon={<Brightness7Icon style={colorIcon} />} // Icône de soleil pour le mode clair
            checkedIcon={<Brightness2Icon />} // Icône de lune pour le mode sombre
        />
    </div>
    );
}

export default ThemeSwitch;
